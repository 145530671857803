import Cookies from 'universal-cookie';
import axios from 'axios';
import { setJWT, getJWT, getTokenFromContext } from './Auth';
import * as cookie from 'cookie'
import { redirect } from './Helpers';

export const API_URL = process.env.NEXT_PUBLIC_API_URL;

export function getUserProfile(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/auth/user-profile', {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function refreshToken(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.post(API_URL + '/auth/refresh', {}, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function dashboardIndex(context = null) {
    let token = getTokenFromContext(context);
    return axios.post(API_URL + '/dashboard/index?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function discussionIndex(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/discussion?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function postAction(action, data) {
    let token = getJWT();
    return axios.post(API_URL + '/discussion/post/'+action+'?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getDiscussionPosts(page: number, tags: string = "") {
    let token = getJWT();
    return axios.get(API_URL + '/discussion/posts?token=' + token, {
        params: { 
            page: page
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getDiscussionPost(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/discussion/post/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function createDiscussionPost(data, socketId) {
    let token = getJWT();
    return axios.post(API_URL + '/discussion/create?token=' + token, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-SOCKET-ID': socketId,
          }
    });
}

export async function updateWatchTime(coursePartId, time) {
    let token = getJWT();
    return axios.post(API_URL + '/course-part/watch-time?token=' + token, {
        coursePartId: coursePartId,
        time: time
    }, {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    });
}

export async function commentDiscussionPost(data, socketId) {
    let token = getJWT();
    return axios.post(API_URL + '/discussion/post/comment?token=' + token, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-SOCKET-ID': socketId,
          }
    });
}

export async function commentReply(data, socketId) {
    let token = getJWT();
    return axios.post(API_URL + '/comment/reply?token=' + token, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            'X-SOCKET-ID': socketId,
          }
    });
}

export async function likeDiscussionPost(data) {
    let token = getJWT();
    return axios.post(API_URL + '/discussion/post/like?token=' + token, data, {
        headers: {
            Authorization: `Bearer ${token}`
          }
    });
}


export async function getNews(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/news?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getNewsArticle(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/news/get/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function affiliateProgram(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/affiliate?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}



export async function instructorProfileSettings(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/instructor/profile/settings?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function saveInstructorProfileSettings(data) {
    let token = getJWT();
    return axios.post(API_URL + '/instructor/profile/settings?token=' + token, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
    });
}

export async function requestPasswordReset(data) {
    let token = getJWT();
    return axios.post(API_URL + '/reset-password', data,
    {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function privateLessonSettings(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/instructor/lessons/settings?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getSubmissions(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/instructor/submissions/refresh?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function savePrivateLessonSettings(data) {
    let token = getJWT();
    return axios.post(API_URL + '/instructor/lessons/settings?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getMyBookings(context = null) {
    let token = getTokenFromContext(context);
    return axios.get(API_URL + '/instructor/bookings?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function requestPayout() {
    let token = getJWT();
    return axios.post(API_URL + '/request-payout?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function defyreLearning(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.post(API_URL + '/dashboard/learning?token=' + token, {}, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function instructorProfile(context = null, instructor_id : number) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.post(API_URL + '/dashboard/learning/instructor?token=' + token, {
        id: instructor_id
    },
    {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function mentoringSchedule(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.post(API_URL + '/dashboard/schedule?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function defyreLive(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/live?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function welcome(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/upgrade/welcome?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getLatestNotification(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/notification/latest?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function instructorDashboard(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/instructor?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function accountSettings(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/account/settings?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function billingSettings(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/account/billing?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function membership(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/account/membership?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function transactions(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/account/transactions?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function saveAccountSettings(data) {
    let token = getJWT();
    return axios.post(API_URL + '/account/update?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}



export async function cancelMembership() {
    let token = getJWT();
    return axios.delete(API_URL + '/account/membership/cancel?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function resumeMembership() {
    let token = getJWT();
    return axios.post(API_URL + '/account/membership/resume?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}



export async function search(query) {
    let token = getJWT();
    return axios.get(`${API_URL}/search/${query}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function updatePaymentMethodPP(data) {
    let token = getJWT();
    return axios.post(API_URL + '/account/payment-method/paypal/update?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function updatePaymentMethodCC(data) {
    let token = getJWT();
    return axios.post(API_URL + '/account/payment-method/card/update?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}



export async function manageSchedule(context = null, page = 1) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(API_URL + '/schedule/manage?token=' + token, {
        params: {
            page: page
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getCourse(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.post(`${API_URL}/dashboard/course/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getBooking(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/booking?token=${token}`, {
        params: {
            id: id
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export async function getLivestream(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/live/get/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getStreamDuration(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/live/current-time/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getWorkshop(id, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/workshop/get/${id}?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function instructorSubmissions(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    return axios.get(`${API_URL}/instructor/submissions?token=${token}`, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getCourses(page: number, tags: string = "") {
    let token = getJWT();
    return axios.get(API_URL + '/dashboard/courses?token=' + token, {
        params: { 
            tags: tags,
            page: page
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function userTracks(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/user-tracks/get?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getUserTrack(id: number, context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }

    return axios.get(API_URL + '/user-tracks/edit?token=' + token, {
        params: {
            track_id: id
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function saveSubmission(title, key, bpm, submissionId) {
    let token = getJWT();
    return axios.post(API_URL + '/user-tracks/create?token=' + token, {   
        title: title,
        key: key,
        bpm: bpm,
        submissionId: submissionId
    }, 
    {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function updateSubmission(id, title, key, bpm, submissionId = null) {
    let token = getJWT();
    return axios.post(API_URL + '/user-tracks/update?token=' + token, {   
        trackId: id,
        title: title,
        key: key,
        bpm: bpm,
        submissionId: submissionId
    }, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function updateTimezone(timezone : string) {
    let token = getJWT();
    return axios.post(API_URL + '/timezone/update?token=' + token, {   
        timezone: timezone,
    }, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function createSubmission(session, track, notes, timezone) {
    let token = getJWT();
    return axios.post(API_URL + '/submission/submit?token=' + token, {   
        stream_id: session.id,
        timezone: timezone,
        track_id: track.id,
        feedbackNotes: notes
    }, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getAllFeedback({context}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/user/feedback?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getSubmissionPosition(stream_id) {
    let token = getJWT();
    
    return axios.get(API_URL + '/submission/position/' + stream_id + '?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getTrackFeedback({context, track_id}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/user-tracks/feedback?token=' + token, {
        params: {
            id: track_id,
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getFeedback({context, track_id}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/feedback?token=' + token, {
        params: {
            id: track_id,
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getTodaysSubmissions({context}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/submissions/today?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getPlatinumSamplePacks({context}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/platinum/sample-packs?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function getPlatinumCoupons({context}) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/platinum/coupons?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function deleteSubmission(id : number) {
    let token = getJWT();
    return axios.delete(API_URL + '/submissions/delete?token=' + token, { 
        params: { 
            id: id,
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}



export async function upgrade(context) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/upgrade?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function getLiveStreamStatus(context = null) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/live/status?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function platinumUpgrade(context) {
    let token = getJWT();
    if(context) {
        const parsedCookies = cookie.parse(context.req.headers.cookie);
        token = parsedCookies.defyreAuth;
    }
    
    return axios.get(API_URL + '/upgrade/platinum?token=' + token, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function sendFeedback(data) {
    let token = getJWT();
    return axios.post(API_URL + '/feedback/send?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function requestMasterAccess(id) {
    let token = getJWT();
    return axios.post(API_URL + '/course-part/download?token=' + token, {
        id: id
    }, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function confirmLessonBooking(data) {
    let token = getJWT();
    return axios.post(API_URL + '/booking/confirm?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function cancelLessonBooking(data) {
    let token = getJWT();
    return axios.post(API_URL + '/booking/cancel?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function completeOrder(data) {
    let token = getJWT();
    return axios.post(API_URL + '/upgrade/complete?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function completePlatinumUpgrade(data) {
    let token = getJWT();
    return axios.post(API_URL + '/upgrade/platinum/complete?token=' + token, data, {
        headers: { Authorization: `Bearer ${token}` }
    });
}


export async function sendChatMessage(stream_id : number, message : string, socketId, workshop : boolean = false) {
    let token = getJWT();
    
    return axios.post(`${API_URL}/live/message/${stream_id}?token=${token}`, {   
       message: message,
       workshop: workshop
    }, 
    {
        headers: {
            'X-SOCKET-ID': socketId,
            Authorization: `Bearer ${token}`
        },
    });
}

export function deleteTrack(id) {
    let token = getJWT();
    return axios.post(API_URL + '/user-tracks/delete?token=' + token, {   
        trackId: id,
    });
}

export async function getSession(id) {
    let token = getJWT();
    return axios.get(API_URL + '/session/get?token=' + token, { 
        params: { 
            id: id,
        },
        headers: { Authorization: `Bearer ${token}` }
    });
}

export async function deleteSession(id : number, others: string) {
    let token = getJWT();
    return axios.delete(API_URL + '/session/delete?token=' + token, { 
        params: { 
            id: id,
            others: others
        },
        headers: { Authorization: `Bearer ${token}` }  
    });
}